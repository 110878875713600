/**
 * @generated SignedSource<<72a749388ae8b5cfa1c0b4f783366564>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type ValidCheckoutStatus = "failed" | "fulfilled" | "processed" | "started" | "validated" | "%future added value";
export type CheckoutCompleteQuery$variables = {
  id: string;
};
export type CheckoutCompleteQuery$data = {
  readonly validCheckout: {
    readonly cart?: string;
    readonly id?: string;
    readonly pricings?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly membershipBenefit: {
            readonly product: {
              readonly slug: string;
              readonly type: ProductType;
            } | null;
          } | null;
          readonly membershipPlan: {
            readonly slug: string;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"CheckoutEntityListItem_PricingFragment">;
        };
      }>;
    };
    readonly status?: ValidCheckoutStatus;
    readonly " $fragmentSpreads": FragmentRefs<"PurchaseTracking_ValidCheckoutFragment">;
  } | null;
};
export type CheckoutCompleteQuery = {
  response: CheckoutCompleteQuery$data;
  variables: CheckoutCompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cart",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutCompleteQuery",
    "selections": [
      {
        "alias": "validCheckout",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingNodeConnection",
                "kind": "LinkedField",
                "name": "pricings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PricingNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pricing",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "membershipPlan",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipBenefit",
                            "kind": "LinkedField",
                            "name": "membershipBenefit",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CheckoutEntityListItem_PricingFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PurchaseTracking_ValidCheckoutFragment"
              }
            ],
            "type": "ValidCheckout",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutCompleteQuery",
    "selections": [
      {
        "alias": "validCheckout",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingNodeConnection",
                "kind": "LinkedField",
                "name": "pricings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PricingNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pricing",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "membershipPlan",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "hideDrafts",
                                    "value": true
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "hideNonPublic",
                                    "value": true
                                  }
                                ],
                                "concreteType": "MembershipBenefitNodeConnection",
                                "kind": "LinkedField",
                                "name": "membershipBenefits",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "membershipBenefits(hideDrafts:true,hideNonPublic:true)"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipBenefit",
                            "kind": "LinkedField",
                            "name": "membershipBenefit",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v2/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startsAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endsAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "membershipPlan",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountCents",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "frequency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ValidCheckout",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81f40756cdd760ae12f694025ac95cfa",
    "id": null,
    "metadata": {},
    "name": "CheckoutCompleteQuery",
    "operationKind": "query",
    "text": "query CheckoutCompleteQuery(\n  $id: ID!\n) {\n  validCheckout: node(id: $id) {\n    __typename\n    ... on ValidCheckout {\n      id\n      status\n      cart\n      pricings {\n        edges {\n          node {\n            id\n            membershipPlan {\n              slug\n              id\n            }\n            membershipBenefit {\n              product {\n                slug\n                type\n                id\n              }\n              id\n            }\n            ...CheckoutEntityListItem_PricingFragment\n          }\n        }\n      }\n      ...PurchaseTracking_ValidCheckoutFragment\n    }\n    id\n  }\n}\n\nfragment CheckoutEntityListItem_PricingFragment on Pricing {\n  id\n  amountCents\n  frequency\n  kind\n  membershipPlan {\n    id\n    name\n    cover\n    membershipBenefits(hideNonPublic: true, hideDrafts: true) {\n      totalCount\n    }\n  }\n  membershipBenefit {\n    id\n    membershipPlan {\n      name\n      id\n    }\n    product {\n      id\n      name\n      cover\n      startsAt\n      endsAt\n      type\n    }\n  }\n}\n\nfragment PurchaseTracking_ValidCheckoutFragment on ValidCheckout {\n  id\n  cart\n  order {\n    id\n    totalPrice\n    currency\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e309933c958eb9b41d2788df4372b29";

export default node;
