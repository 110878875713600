import { CheckoutCompleteQuery } from "@/checkout/complete/__generated__/CheckoutCompleteQuery.graphql"
import CheckoutEntityListItem from "@/checkout/components/CheckoutEntityListItem"
import CheckoutError from "@/checkout/error/CheckoutError"
import { CheckoutProcessSkeleton } from "@/checkout/process/CheckoutProcess"
import { CheckoutUtils } from "@/checkout/utils/CheckoutUtils"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import useTrackPurchase from "@/organization/tracking/lib/PurchaseTracking"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

type Props = TestIDProps & {
  validCheckoutId: GlobalID
}

function CheckoutComplete({ testid = "CheckoutComplete", validCheckoutId }: Props) {
  const classes = useStyles()
  const { authUser } = useAuthUser()
  const history = useHistory()
  const drawer = useGlobalDrawer("checkout")
  const experienceLabel = useLabel("experience")
  const pathwayLabel = useLabel("pathway")

  const { validCheckout } = useLazyLoadQuery<CheckoutCompleteQuery>(
    graphql`
      query CheckoutCompleteQuery($id: ID!) {
        validCheckout: node(id: $id) {
          ... on ValidCheckout {
            id
            status
            cart
            pricings {
              edges {
                node {
                  id
                  membershipPlan {
                    slug
                  }
                  membershipBenefit {
                    product {
                      slug
                      type
                    }
                  }
                  ...CheckoutEntityListItem_PricingFragment
                }
              }
            }
            ...PurchaseTracking_ValidCheckoutFragment
          }
        }
      }
    `,
    { id: validCheckoutId }
  )

  const pricings = Relay.connectionToArray(validCheckout?.pricings)
  const pricingsById = ArrayUtils.mapBy(pricings, "id")
  const trackPurchase = useTrackPurchase(validCheckout)

  // Track the purchase if successful
  useEffect(() => {
    if (validCheckout?.status !== "processed") return
    trackPurchase?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If the status is not processed, show an error
  if (!validCheckout || validCheckout.status !== "processed") {
    return (
      <CheckoutError
        cta={<DiscoButton onClick={returnToSummary}>{"Return to Checkout"}</DiscoButton>}
      />
    )
  }

  return (
    <div data-testid={testid} className={classes.container}>
      <div className={classes.content}>
        <DiscoText color={"primary.main"} variant={"body-xs-600-uppercase"}>
          {"Confirmation"}
        </DiscoText>

        <DiscoText variant={"heading-md-700"} align={"center"}>
          {authUser?.firstName ? `Thank you, ${authUser.firstName}!` : "Thank you!"}
        </DiscoText>

        <DiscoText variant={"body-md"} color={"text.secondary"} align={"center"}>
          {`You've successfully registered for the ${pluralize(
            "items",
            pricings.length
          )} listed below:`}
        </DiscoText>

        <div className={classes.pricings}>
          {pricings.map((pricing) => {
            const product = pricing.membershipBenefit?.product || pricing.membershipPlan
            if (!product) return null

            return (
              <CheckoutEntityListItem
                key={pricing.id}
                testid={`${testid}.item.${product.slug}`}
                pricingKey={pricing}
                onSelect={navigateToEntity}
              />
            )
          })}
        </div>

        {renderButton()}
      </div>
    </div>
  )

  function renderButton() {
    // If multiple entities were purchased, or purchasing only a membership plan, go to community home
    const isPurchasingMultiple = pricings.length > 1
    const isPurchasingMembershipPlan = pricings.some((pricing) => pricing.membershipPlan)
    if (isPurchasingMultiple || isPurchasingMembershipPlan) {
      return (
        <DiscoButton testid={`${testid}.button.community`} onClick={navigateToHome}>
          {"Continue to Community"}
        </DiscoButton>
      )
    }

    // If purchasing an experience or pathway, show the button to go to the product
    const pricing = pricings[0]
    const product = pricing.membershipBenefit?.product
    const productLabel = product?.type === "pathway" ? pathwayLabel : experienceLabel

    return (
      <DiscoButton
        testid={`${testid}.button.${product?.slug}`}
        onClick={() => navigateToEntity(pricing.id)}
      >
        {`Continue to ${productLabel.singular}`}
      </DiscoButton>
    )
  }

  function navigateToEntity(pricingId: GlobalID) {
    const pricing = pricingsById[pricingId]

    const path = pricing.membershipBenefit?.product
      ? generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
          productSlug: pricing.membershipBenefit.product.slug,
        })
      : ROUTE_NAMES.COMMUNITY.HOME.ROOT

    if (drawer.isOpen) history.push(path)
    else window.open(path, "_self")
  }

  function navigateToHome() {
    const path = ROUTE_NAMES.COMMUNITY.HOME.ROOT

    if (drawer.isOpen) history.push(path)
    else window.open(path, "_self")
  }

  function returnToSummary() {
    const cart = validCheckout?.cart ? JSON.parse(validCheckout.cart) : { items: [] }
    const encodedCart = CheckoutUtils.encodeCart(cart)

    if (drawer.isOpen) {
      drawer.setParams({ cart: encodedCart, checkoutStep: "summary" })
    } else {
      history.push(generatePath(ROUTE_NAMES.CHECKOUT.SUMMARY, { cart: encodedCart }))
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2.5),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "750px",
    gap: theme.spacing(1),
    justifyContent: "flex-start",
    height: "75%",

    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  pricings: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    width: "100%",
    margin: theme.spacing(5, 0),
  },
}))

function CheckoutCompleteSkeleton() {
  return <CheckoutProcessSkeleton />
}

export default Relay.withSkeleton({
  component: CheckoutComplete,
  skeleton: CheckoutCompleteSkeleton,
})
